import React, { useState, useEffect } from "react";
import { Table, Form, FormControl, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";

const searchData = (data, search) => {
  if (search === "") {
    return data;
  }

  return data.filter((row) => {
    return (
      row.kbdetail.toLowerCase().includes(search.toLowerCase()) ||
      row.fileversion.toLowerCase().includes(search.toLowerCase()) ||
      row.buildnumber.toLowerCase().includes(search.toLowerCase())
    );
  });
};

const TableComponent = ({
  data,
  searchQuery,
  setSearchQuery,
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
}) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(searchData(data, searchQuery));
  }, [data, searchQuery]);

  const pageCount = Math.ceil(filteredData.length / pageSize);

  const handlePageChange = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = currentPage * pageSize;
  const displayedData = filteredData.slice(startIndex, startIndex + pageSize);

  return (
    <div className="Sqlfilter table-container">
      <Form inline className="mb-2">
        <br></br>
        <FormControl
          type="text"
          placeholder="Search"
          className="mr-sm-2"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
      </Form>

      <Table striped bordered hover size="lg" responsive>
        <thead className="tablehead">
          <tr>
            {/* <th>Knowledge Id</th>
            <th>Product Version</th>
            <th>Knowledge Number</th> */}
            <th>Details</th>
            <th>Download URL</th>
            <th>Release Date</th>
            <th>Build Number</th>
            <th>File Version</th>
            {/* Adding more columns here */}
          </tr>
        </thead>
        <tbody className="tablebody">
          {displayedData.map((item) => (
            <tr key={item.buildnumber}>
              {/* <td>{item.knowledge_id}</td>
              <td>{item.product_version_id}</td>
              <td>{item.knowledge_number}</td> */}
              <td>{item.kbdetail}</td>
              <td>
                <a href={item.kburl} target="_blank" rel="noopener noreferrer">
                  {item.kburl}
                </a>
              </td>
              <td>{item.releasedate}</td>
              <td>{item.buildnumber}</td>
              <td>{item.fileversion}</td>
              {/* Adding more columns here */}
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination size="sm" className="justify-content-center">
        {[...Array(pageCount)].map((page, i) => (
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={(e) => handlePageChange(e, i)}>
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
      <label>
        Items per page:
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setCurrentPage(0);
          }}>
          {[30, 10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

const AllMySqlDB = () => {
  const [allMysqlDb, SetAllMysqlDb] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2030");
      const data = await response.json();
      SetAllMysqlDb(data);
    }
    fetchData();
  }, []);

  return (
    <>
      <section id="table" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 mx-auto">
              <div className="row">
                {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}
                <div id="tableheads">
                  <h1>All MYSQL Database Versions List </h1>
                  <Link
                    to="/MySqlDropdown"
                    className="btn headerbutton btn-outline-primary">
                    Back To Previous Page
                  </Link>
                </div>
                <TableComponent
                  data={allMysqlDb}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

const MySqlDB1 = () => {
  const [mysqlOne, SetMysqlOne] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2021");
      const data = await response.json();
      SetMysqlOne(data);
    }
    fetchData();
  }, []);

  return (
    <>
      <section id="table" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}
                <div id="tableheads">
                  <h1>MYSQL v8.0.0 </h1>
                  <Link
                    to="/MySqlDropdown"
                    className="btn headerbutton btn-outline-primary">
                    Back To Previous Page
                  </Link>
                </div>
                <TableComponent
                  data={mysqlOne}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
    </>
  );
};
const MySqlDB2 = () => {
  const [mysqlTwo, SetMysqlTwo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2020");
      const data = await response.json();
      SetMysqlTwo(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MySQL v5.7.1 m11</h1>
                <Link
                  to="/MySqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>

              <TableComponent
                data={mysqlTwo}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MySqlDB3 = () => {
  const [mysqlThree, SetMysqlThree] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2018");
      const data = await response.json();
      SetMysqlThree(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MySQL 5.6.2 m5</h1>
                <Link
                  to="/MySqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={mysqlThree}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MySqlDB4 = () => {
  const [mysqlFour, SetMysqlFour] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2015");
      const data = await response.json();
      SetMysqlFour(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MySQL 5.5.0 m2</h1>
                <Link
                  to="/MySqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={mysqlFour}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MySqlDB5 = () => {
  const [mysqlFive, SetMysqlFive] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2013");
      const data = await response.json();
      SetMysqlFive(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MySQL 5.1.5a alpha</h1>
                <Link
                  to="/MySqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={mysqlFive}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MySqlDB6 = () => {
  const [mysqlSix, SetMysqlSix] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2011");
      const data = await response.json();
      SetMysqlSix(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MySQL 5.0.15</h1>
                <Link
                  to="/MySqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={mysqlSix}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AllPostgreSqlDB = () => {
  const [allPostgreDb, SetAllPostgreDb] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2031");
      const data = await response.json();
      SetAllPostgreDb(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>All PostgreSQL Versions List</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={allPostgreDb}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB1 = () => {
  const [postgreOne, SetPostgreOne] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2003");
      const data = await response.json();
      SetPostgreOne(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V8.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreOne}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB2 = () => {
  const [postgreTwo, SetPostgreTwo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2010");
      const data = await response.json();
      SetPostgreTwo(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V1.0.8</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreTwo}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const PostgreSqlDB3 = () => {
  const [postgreThree, SetPostgreThree] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2007");
      const data = await response.json();
      SetPostgreThree(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V1.0.9</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>

              <TableComponent
                data={postgreThree}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const PostgreSqlDB4 = () => {
  const [postgreFour, SetPostgreFour] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2006");
      const data = await response.json();
      SetPostgreFour(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V6.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreFour}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const PostgreSqlDB5 = () => {
  const [postgreFive, SetPostgreFive] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2004");
      const data = await response.json();
      SetPostgreFive(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V7.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreFive}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB6 = () => {
  const [postgreSix, SetPostgreSix] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2002");
      const data = await response.json();
      SetPostgreSix(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V9.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreSix}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB7 = () => {
  const [postgreSeven, SetPostgreSeven] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2023");
      const data = await response.json();
      SetPostgreSeven(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V10.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreSeven}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB8 = () => {
  const [postgreEight, SetPostgreEight] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2024");
      const data = await response.json();
      SetPostgreEight(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V11.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreEight}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB9 = () => {
  const [postgreNine, SetPostgreNine] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2025");
      const data = await response.json();
      SetPostgreNine(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V12.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreNine}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB10 = () => {
  const [postgreTen, SetPostgreTen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2026");
      const data = await response.json();
      SetPostgreTen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V13.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreTen}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB11 = () => {
  const [postgreEleven, SetPostgreEleven] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2027");
      const data = await response.json();
      SetPostgreEleven(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V14.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>

              <TableComponent
                data={postgreEleven}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PostgreSqlDB12 = () => {
  const [postgreTwelve, SetPostgreTwelve] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2028");
      const data = await response.json();
      SetPostgreTwelve(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>PostgreSQL V15.0.0</h1>
                <Link
                  to="/PostgreSqlDropdown "
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={postgreTwelve}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const AllMsSqlDB = () => {
  const [allMsSqlDb, SetAllMsSqlDb] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2029");
      const data = await response.json();
      SetAllMsSqlDb(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col mx-auto tablecolumn">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>All Microsoft SQL Server Updates List</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={allMsSqlDb}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MsSql2022 = () => {
  const [releaseOne, SetReleaseOne] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2022");
      const data = await response.json();
      SetReleaseOne(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2022</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseOne}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2019 = () => {
  const [releaseTwo, SetReleaseTwo] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2019");
      const data = await response.json();
      SetReleaseTwo(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2019</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseTwo}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2017 = () => {
  const [releaseThree, SetReleaseThree] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2017");
      const data = await response.json();
      SetReleaseThree(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2017</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseThree}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2016 = () => {
  const [releaseFour, SetReleaseFour] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2016");
      const data = await response.json();
      SetReleaseFour(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2016</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseFour}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2014 = () => {
  const [releaseFive, SetReleaseFive] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2014");
      const data = await response.json();
      SetReleaseFive(data);
    }
    fetchData();
  }, []);

  console.log(releaseFive);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2014</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>

              <TableComponent
                data={releaseFive}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2012 = () => {
  const [releaseSix, SetReleaseSix] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2012");
      const data = await response.json();
      SetReleaseSix(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2012</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseSix}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2008R2 = () => {
  const [releaseSeven, SetReleaseSeven] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2009");
      const data = await response.json();
      SetReleaseSeven(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2008 R2</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseSeven}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2008 = () => {
  const [releaseEight, SetReleaseEight] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2008");
      const data = await response.json();
      SetReleaseEight(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2008</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseEight}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2005 = () => {
  const [releaseNine, SetReleaseNine] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2005");
      const data = await response.json();
      SetReleaseNine(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2005</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseNine}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MsSql2000 = () => {
  const [releaseTen, SetReleaseTen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/release2001");
      const data = await response.json();
      SetReleaseTen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>Microsoft SQL Server 2000</h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseTen}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SuUpdate = () => {
  const [releaseEleven, SetReleaseEleven] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/SuUpdate");
      const data = await response.json();
      SetReleaseEleven(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <h1>MYSQL v8.0.0 </h1>
                <Link
                  to="/MsSqlDropdown"
                  className="btn headerbutton btn-outline-primary">
                  Back To Previous Page
                </Link>
              </div>
              <TableComponent
                data={releaseEleven}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FixErrorMessages = () => {
  const [releaseTwelve, SetReleaseTwelve] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/FixErrorMessages");
      const data = await response.json();
      SetReleaseTwelve(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseTwelve}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const Azure = () => {
  const [releaseThirteen, SetReleaseThirteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/Azure");
      const data = await response.json();
      SetReleaseThirteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseThirteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const GDR = () => {
  const [releaseFourteen, SetReleaseFourteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/GDR");
      const data = await response.json();
      SetReleaseFourteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseFourteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const MS = () => {
  const [releaseFifteen, SetReleaseFifteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/MS");
      const data = await response.json();
      SetReleaseFifteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseFifteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CriticalUpdate = () => {
  const [releaseSixteen, SetReleaseSixteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/CriticalUpdate");
      const data = await response.json();
      SetReleaseSixteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseSixteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const TLS = () => {
  const [releaseSeventeen, SetReleaseSeventeen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/TLS");
      const data = await response.json();
      SetReleaseSeventeen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseSeventeen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Hotflix = () => {
  const [releaseEighteen, SetReleaseEighteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/Hotflix");
      const data = await response.json();
      SetReleaseEighteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseEighteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const RS = () => {
  const [releaseNineteen, SetReleaseNineteen] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/RS");
      const data = await response.json();
      SetReleaseNineteen(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseNineteen}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Servicing = () => {
  const [releaseTwenty, SetReleaseTwenty] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/Servicing");
      const data = await response.json();
      SetReleaseTwenty(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseTwenty}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const RTM = () => {
  const [releaseTwentyOne, SetReleaseTwentyone] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetch("/RTM");
      const data = await response.json();
      SetReleaseTwentyone(data);
    }
    fetchData();
  }, []);

  return (
    <section id="table" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              {/* <div className='col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column'> */}

              <div id="tableheads">
                <TableComponent
                  data={releaseTwentyOne}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export {
  AllMsSqlDB,
  AllMySqlDB,
  AllPostgreSqlDB,
  MySqlDB1,
  MySqlDB2,
  MySqlDB3,
  MySqlDB4,
  MySqlDB5,
  MySqlDB6,
  PostgreSqlDB1,
  PostgreSqlDB2,
  PostgreSqlDB3,
  PostgreSqlDB4,
  PostgreSqlDB5,
  PostgreSqlDB6,
  PostgreSqlDB7,
  PostgreSqlDB8,
  PostgreSqlDB9,
  PostgreSqlDB10,
  PostgreSqlDB11,
  PostgreSqlDB12,
  MsSql2022,
  MsSql2019,
  MsSql2017,
  MsSql2016,
  MsSql2014,
  MsSql2012,
  MsSql2008R2,
  MsSql2008,
  MsSql2005,
  MsSql2000,
  SuUpdate,
  FixErrorMessages,
  Azure,
  GDR,
  MS,
  CriticalUpdate,
  TLS,
  Hotflix,
  RS,
  Servicing,
  RTM,
};
