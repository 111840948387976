import React from "react";
import { Link } from "react-router-dom";
import ClotaLogo from "./ClotaLogo.jpg";

//import "./index.css";

const Footer = () => (
    <div className="container-fluid bg-dark text-white">
      <footer id="footer">
        <div className="row">
          <div className="col-md mt-3">
           <Link to="/" className="ms-5"
              ><img
                src={ClotaLogo}
                alt="clotalogo"
                height="95"
                width="99"
                className="rounded mt-2 ms-3"
              />
              
            </Link>
            <Link to="/"><h4>Clota Technology</h4></Link>
            <p>It's our passion for technologies which lead us here.</p>
          </div>

          <div className="col-md sections">
            <ul>
              <h5>Site Links</h5>
              <li>
               <Link to="/home">Home</Link>
              </li>
              <li>
               <Link to="/about">About</Link>
              </li>
              <li>
               <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md sections2">
            <ul>
              <h5>
               <a href="mailto:support@clotatechnology.com?subject=Enquiry">Quick Connect</a>
              </h5>
              <hr />
              <h5>
               <a href="https://www.clotatech.com" target="_clota">Visit Us</a>
              </h5>
            </ul>
          </div>
          <div className="col-md sections3">
            <ul>
              <h5>Stay In Touch</h5>
              <a href="https://www.facebook.com/ClotaTech" target="_clota"><i className="Social-icons fa-brands fa-facebook"></i></a>
             <a href="https://www.instagram.com/ClotaTech/" target="_clota"><i className="Social-icons fa-brands fa-instagram"></i></a>
             <a href="https://twitter.com/ClotaTech" target="_clota"><i className="Social-icons fa-brands fa-twitter"></i></a>
             <a href="https://www.linkedin.com/in/ClotaTech" target="_clota"><i className="Social-icons fa-brands fa-linkedin"></i></a>
             <a href="https://api.whatsapp.com/send?phone=917569192527&text=hi i am <EnterYourName> and i'm enquiriying through your dbversion portal" target="_clota"><i class="fa-brands fa-whatsapp"></i></a>
            </ul>
            <a href="tel:756-919-2527"><i class="fa-sharp fa-solid fa-phone-volume"></i></a>
            <a href="tel:756-919-2527" className="h6 ms-3">Click To Call +91 75691-92527</a> 
           <hr></hr>
            <a href="tel:756-919-2527"><i class="fa-sharp fa-solid fa-envelope mr-4"></i></a> 
            <a href="mailto:support@clotatechnology.com?subject=Enquiry"className="h6 ms-3">support@clotatechnology.com </a>
            <p></p>
          </div>
        </div>
        <p className="Copyright">
          © Copyright 2024 Clota Technology - All Rights Reserved
        </p>
      </footer>
    </div>

);

export default Footer;
