import contactus from "../src/images/contactus.jpg";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';


const Contact=()=> {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_rxsxxvr', 'template_hxofz3c', form.current, 'wdBv7G8tu3EMzZJ2N')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

   
  };
  


  return (
    <>
    <section id="contactbox" className="d-flex align-items-center">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="row">
              <div className="col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                <div className="col-lg-10 contactbox-img">
                  <img
                    src={contactus}
                    className="img-fluid animated"
                    alt="header"
                  ></img>
                </div>
                <h1>
                Having doubts ? 
                  <strong className="brand-name">Feel free to Ask!!</strong>
                </h1>
                <br></br>
                <h3>
                   <b>Thank you for visiting our site</b>
                 </h3>  
                <p>
                Our website is a one-stop resource for information on version
                updates, security updates, and bug fixes for popular databases
                   such as MySQL, PostgreSQL, and Oracle. We provide release
                   notes, download links, and instructions on how to apply the
                   updates, all in one place. If you have any queries and
                   suggestions feel free to contact us by telephone or email. We
                   will be sure to get back to you as soon asv
                </p>
                <hr class="border border-primary border-1 opacity-75" />
                <h3>Your Suggestions Below</h3>
                <div className="container-md border border-success mt-2 pb-2 mb-5 w-50">
                 
                <form ref={form} onSubmit={sendEmail} className="needs-validation">
                      <div className="userid">
                        <label for="uid" className="form-label ">   
                          Your Name:
                        </label>
                        <input
                          type="text"
                          className="placeholdars form-control form-control-md w-75"
                          id="uid"
                          placeholder="Enter Full Name"
                          name=" uid "
                          required
                        />
                        <div className="valid-feedback"> Valid data. </div>
                        <div className="invalid-feedback">   
                          Please fill the user name.
                        </div>
                      </div>
                      <div className="emailid">
                        <label for="emid" className="form-label">       
                          Email Id:
                        </label>
                        <input
                          type="email"
                          className="placeholdars form-control form-control-md w-75"
                          id=" emid "
                          placeholder="Enter Your EmailId"
                          name=" emid "
                          required
                        />
                        <div className="valid-feedback"> Valid data. </div>
                        <div className="invalid-feedback">  
                          Please fill the email id.
                        </div>
                      </div>
                      <div className="numberid">
                        <label for="numid" className="form-label">  
                          Mobile Number:
                        </label>
                        <input
                          type="number"
                          className="placeholdars form-control form-control-md w-75"
                          id=" numid "
                          placeholder="Enter Mobile Number"
                          name="numid"
                          required
                        />
                        <div className="valid-feedback"> Valid data. </div>
                        <div className="invalid-feedback">   
                          Please fill the mobile number.
                        </div>
                      </div>
                      <div className="txt">
                        <label for="txt" className="form-label">   
                          Message:
                        </label>
                        <textarea
                          className="placeholdars form-control form-control-md w-75"
                          id="txt"
                          placeholder="Enter message"
                          name="txt"
                          required
                        ></textarea>
                        <div className="valid-feedback"> Valid data. </div>
                        <div className="invalid-feedback">  
                          Please fill the message.
                        </div>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="Checked"
                          name="remembers"
                          required
                        />
                        <label className="form-check-label" for="Checked">   
                          I agree on form validation.
                        </label>
                        <div className="valid-feedback"> </div>
                        <div className="invalid-feedback"> </div>
                      </div>
                      <button type="submit" className="btn btn-info submitbtn mt-2" value="send">
                        Submit
                      </button>
                    </form>  
                </div>
                <hr class="border border-primary border-1 opacity-75 mb-5" />  
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
}

export default Contact;

