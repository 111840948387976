import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <section id="aboutpage" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    <strong className="brand-name">ABOUT WEBSITE</strong>
                  </h1>
                  <h3>Introducing the One-Stop Database Update Site</h3>
                  <p>
                    Are you tired of scouring the internet for information on
                    the latest version updates, security updates, and bug fixes
                    for your databases? Look no further! Our website is here to
                    provide all of the information you need in one convenient
                    location.<br></br>
                    <br></br>
                    We understand the importance of keeping your databases up to
                    date. Not only do updates fix known issues and improve
                    performance, but they also keep your databases secure and
                    protect against vulnerabilities. That's why we've created a
                    website that makes it easy to find the information all you
                    need. Our website is a one-stop resource for information on
                    version updates, security updates, and bug fixes for popular
                    databases such as MySQL, PostgreSQL, and Oracle. We provide
                    release notes, download links, and instructions on how to
                    apply the updates, all in one place. Don't waste any more
                    time searching for database update information. Visit our
                    site today and stay up to date with the latest and greatest.
                  </p>
                  <hr class="border border-primary border-1 opacity-75" />
                  <h3>Get Start Now</h3>
                  <div className="my-3">
                    <Link
                      to="/"
                      className="btn headerbutton btn-outline-primary"
                    >
                      Click Here
                    </Link>
                  </div>
                </div>

                {/* <div className='col-lg-6 order-1 order-lg-2 radiobutton-img'>
          <img src={header} className='img-fluid animated' alt='header'></img>
          </div>   */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
