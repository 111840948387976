import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import MSSQL from "../images/MSSQL.png";
import MySQL from "../images/MySQL.png";
import PostgreSQL from "../images/PostgreSQL.jpeg";

const MsSqlDropdown = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      const response = await fetch("/selectproduct");
      const data = await response.json();
      setProducts(data);
    }
    fetchProducts();
  }, []);
  return (
    <>
      <section id="dropdownbox" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
              <h1>
              <strong className="brand-name">
                      Microsoft SQL Database
                    </strong>
                   </h1>
                   <p>
                    MSSQL, developed by Microsoft, is a proprietary RDBMS that
                    is often used in Windows-based environments. It offers
                    robust features for data warehousing, business intelligence
                    and analytics. It is known for its scalability and
                    performance, and can handle large amounts of data and
                    concurrent users. It also offers various features like
                    Always On Availability Group, In-memory OLTP and other
                    features that makes it popular in enterprise environments.
                  </p>
                 
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                 
                  <hr class="border border-primary border-1 opacity-75" />
                  <h3>
                    Choose Your Database Version
                    <h5>(eg. MSSQL 2022 , MSSQL 2019...)</h5>
                  </h3>
                  <div className="my-3 dropdownboxbutton">
                    <DropdownButton
                      // className='dropfilter'
                      id="product-dropdown"
                      title="MSSQL"
                      variant="primary"
                    >
                      {products.map((product) => (
                        <div className="scrollable-menu">
                          <Dropdown.Item
                            key={product}
                            as={Link}
                            to={`/products/${product.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            )}`}
                          >
                            {product}
                          </Dropdown.Item>
                        </div>
                      ))}
                    </DropdownButton>
                    <div className="backbutton">
                      <Link
                        to="/"
                        className="btn headerbutton btn-outline-primary"
                      >
                        Go Back
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2 dropdownbox-img">
                  <img
                    src={MSSQL}
                    className="img-fluid animated"
                    alt="MSSQL"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const PostgreSqlDropdown = () => {
  const [postgresql, setPostgresql] = useState([]);

  useEffect(() => {
    async function fetchPostgresql() {
      const response = await fetch(
        "/postgresqldatabase"
      );
      const data = await response.json();
      setPostgresql(data);
    }
    fetchPostgresql();
  }, []);

  return (
    <>
      <section id="dropdownbox" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <h1>
                  <strong className="brand-name">PostgreSql Databse</strong>
                </h1>
                <p>
                  PostgreSQL is a powerful, open-source RDBMS that is known for
                  its advanced feature set and strong support for data integrity
                  and consistency. It has a rich set of data types, operators
                  and functions that can be used to handle complex data
                  structures and relationships. It also offers built-in support
                  for data replication, full-text search, and support for many
                  programming languages, including C, C++, Java, .Net, Perl,
                  Python, Ruby and more.
                </p>
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <hr class="border border-primary border-1 opacity-75" />
                  <h3>
                    Choose Your Database Version
                    <h5>(eg. PostgreSQL v9.0 , PostgreSQLSql v8.0...)</h5>
                  </h3>
                  <div className="my-3 dropdownboxbutton ">
                    <DropdownButton
                      // className='dropfilter'
                      id="postgresql-dropdown"
                      title="POSTGRESQL"
                      variant="warning"
                    >
                      {postgresql.map((postgresql) => (
                        <div className="scrollable-menu">
                          <Dropdown.Item
                            key={postgresql}
                            as={Link}
                            to={`/postgresql/${postgresql.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            )}`}
                          >
                            {postgresql}
                          </Dropdown.Item>
                        </div>
                      ))}
                    </DropdownButton>

                    <div className="backbutton">
                      <Link
                        to="/"
                        className="btn headerbutton btn-outline-primary"
                      >
                        Go Back
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2 dropdownbox-img">
                  <img
                    src={PostgreSQL}
                    className="img-fluid animated"
                    alt="PostgreSQL"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const MySqlDropdown = () => {
  const [mysqldatabase, setMysqldatabase] = useState([]);

  useEffect(() => {
    async function fetchMysqldatabase() {
      const response = await fetch("/mysqldatabase");
      const data = await response.json();
      setMysqldatabase(data);
    }
    fetchMysqldatabase();
  }, []);

  return (
    <>
      <section id="dropdownbox" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <h1>
                  <strong className="brand-name">MYSQL Database</strong>
                </h1>
                <p>
                  MySQL is an open-source RDBMS that is widely used in web
                  development because of its ease of use and performance. It is
                  known for its high-performance and low-cost. It's a popular
                  choice for small- to medium-sized projects and web
                  applications, and is also used in many popular content
                  management systems (CMS) such as WordPress and Joomla. It also
                  offers support for various programming languages such as C,
                  C++, Java, Perl, PHP, Python, and Ruby.
                </p>
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <hr class="border border-primary border-1 opacity-75" />
                  <h3>
                    Choose Your Database Version
                    <h5>(eg. MySql 8.0 ,MySql 5.7.1...)</h5>
                  </h3>
                  <div className="my-3 dropdownboxbutton">
                    <DropdownButton
                      // className='dropfilter'
                      id="mysqldatabase-dropdown"
                      title="MYSQL"
                      variant="success"
                    >
                      {mysqldatabase.map((mysqldatabase) => (
                        <div className="scrollable-menu ">
                          <Dropdown.Item
                            key={mysqldatabase}
                            as={Link}
                            to={`/mysqldatabase/${mysqldatabase.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            )}`}
                          >
                            {mysqldatabase}
                          </Dropdown.Item>
                        </div>
                      ))}
                    </DropdownButton>
                    <div className="backbutton">
                      <Link
                        to="/"
                        className="btn headerbutton btn-outline-primary"
                      >
                        Go Back
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 order-1 order-lg-2 dropdownbox-img">
                  <img
                    src={MySQL}
                    className="img-fluid animated"
                    alt="MySQL"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Dropdownboxes = () => {
  return (
    <div>
      <DropdownButton
        // className='dropfilter'
        id="product-dropdown"
        title="Select a product"
        variant="primary"
      >
        <div className="scrollable-menu">
          <Dropdown.Item as={Link} to="/SuUpdate">
            Security Updates(SU)
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/CriticalUpdate">
            Critical Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/FixErrorMessages">
            FIX Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/GDR">
            General Distribution Release Update(GDR)
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/RTM">
            Release to Manufacturing Update(RTM)
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/MS">
            Microsoft Update(MS)
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/TLS">
            Transport Layer Security(TLS)
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/RS">
            Reporting Service Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/Hotflix">
            Hotflix Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/AzureConnect">
            Azure Connect Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/Servicing">
            Servicing Update
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/CU">
            Cumulative Updates(CU)
          </Dropdown.Item>
        </div>
      </DropdownButton>
    </div>
  );
};

export { MsSqlDropdown, MySqlDropdown, PostgreSqlDropdown, Dropdownboxes };
