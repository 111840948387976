import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "../About";
import Contact from "../Contact";
import Header from "../Header";
import React from "react";
import Home from "./Home";
import {
  MsSqlDropdown,
  MySqlDropdown,
  PostgreSqlDropdown,
  Dropdownboxes,
} from "./Dropdowns";
import Footer from "../Footer";
import {
  AllMsSqlDB,
  AllMySqlDB,
  AllPostgreSqlDB,
  MySqlDB1,
  MySqlDB2,
  MySqlDB3,
  MySqlDB4,
  MySqlDB5,
  MySqlDB6,
  PostgreSqlDB1,
  PostgreSqlDB2,
  PostgreSqlDB3,
  PostgreSqlDB4,
  PostgreSqlDB5,
  PostgreSqlDB6,
  PostgreSqlDB7,
  PostgreSqlDB8,
  PostgreSqlDB9,
  PostgreSqlDB10,
  PostgreSqlDB11,
  PostgreSqlDB12,
  MsSql2022,
  MsSql2019,
  MsSql2017,
  MsSql2016,
  MsSql2014,
  MsSql2012,
  MsSql2008R2,
  MsSql2008,
  MsSql2005,
  MsSql2000,
  SuUpdate,
  FixErrorMessages,
  Azure,
  GDR,
  MS,
  CriticalUpdate,
  TLS,
  Hotflix,
  RS,
  Servicing,
  RTM,
} from "../tables/TableComponent";

function PageRoutes() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/MsSqlDropdown" element={<MsSqlDropdown />} />
        <Route path="/MySqlDropdown" element={<MySqlDropdown />} />
        <Route path="/PostgreSqlDropdown" element={<PostgreSqlDropdown />} />
        <Route path="/Dropdownboxes" element={<Dropdownboxes />} />
        {/*--------------MSSQL DROPDOWN----------*/}
        <Route path="products/AllMicrosoftSQLServerVersions" element={<AllMsSqlDB />} />
        <Route path="products/MicrosoftSQLServer2022" element={<MsSql2022 />} />
        <Route
          path="/products/MicrosoftSQLServer2019"
          element={<MsSql2019 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2017"
          element={<MsSql2017 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2016"
          element={<MsSql2016 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2014"
          element={<MsSql2014 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2012"
          element={<MsSql2012 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2008R2"
          element={<MsSql2008R2 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2008"
          element={<MsSql2008 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2005"
          element={<MsSql2005 />}
        />
        <Route
          path="/products/MicrosoftSQLServer2000"
          element={<MsSql2000 />}
        />
        {/*-------MySql Dropdown------------*/}
        <Route path="/mysqldatabase/AllMYSQLDatabaseVersionsList" element={<AllMySqlDB />} />
        <Route path="/mysqldatabase/MySQL800dmr" element={<MySqlDB1 />} />
        <Route path="/mysqldatabase/MySQL571m11" element={<MySqlDB2 />} />
        <Route path="/mysqldatabase/MySQL562m5" element={<MySqlDB3 />} />
        <Route path="/mysqldatabase/MySQL550m2" element={<MySqlDB4 />} />
        <Route path="/mysqldatabase/MySQL515aalpha" element={<MySqlDB5 />} />
        <Route path="/mysqldatabase/MySQL5015" element={<MySqlDB6 />} />
        {/*--------Postgrade Dropdown-------*/}
        <Route path="/postgresql/AllPostgreSQLDatabaseVersionsList" element={<AllPostgreSqlDB />} />
        <Route path="/postgresql/PostgreSQLV800" element={<PostgreSqlDB1 />} />
        <Route path="/postgresql/PostgreSQLV700" element={<PostgreSqlDB5 />} />
        <Route path="/postgresql/PostgreSQLV600" element={<PostgreSqlDB4 />} />
        <Route path="/postgresql/PostgreSQLV109" element={<PostgreSqlDB3 />} />
        <Route path="/postgresql/Postgres95V108" element={<PostgreSqlDB2 />} />
        <Route path="/postgresql/PostgreSQLV900" element={<PostgreSqlDB6 />} />
        <Route path="/postgresql/PostgreSQLV100" element={<PostgreSqlDB7 />} />
        <Route path="/postgresql/PostgreSQLV110" element={<PostgreSqlDB8 />} />
        <Route path="/postgresql/PostgreSQLV120" element={<PostgreSqlDB9 />} />
        <Route path="/postgresql/PostgreSQLV130" element={<PostgreSqlDB10 />} />
        <Route path="/postgresql/PostgreSQLV140" element={<PostgreSqlDB11 />} />
        <Route path="/postgresql/PostgreSQLV150" element={<PostgreSqlDB12 />} />
        <Route path="/SuUpdate" element={<SuUpdate />} />
        <Route path="/FixErrorMessages" element={<FixErrorMessages />} />
        <Route path="/Azure" element={<Azure />} />
        <Route path="/GDR" element={<GDR />} />
        <Route path="/MS" element={<MS />} />
        <Route path="/CriticalUpdate" element={<CriticalUpdate />} />
        <Route path="/TLS" element={<TLS />} />
        <Route path="/Hotflix" element={<Hotflix />} />
        <Route path="/RS" element={<RS />} />
        <Route path="/Servicing" element={<Servicing />} />
        <Route path="/RTM" element={<RTM />} />
      </Routes>
      {/* <Footertesting/> */}
      <Footer />
    </Router>
  );
}

export default PageRoutes;
