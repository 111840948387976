import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section id="radiobutton" className="d-flex align-items-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-md-12 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h1>
                    Welcome to
                    <strong className="brand-name">Database Version</strong>
                  </h1>
                  <br></br>
                  <h2>
                    Where you can find all the database related updates,
                    releases and fixes in one place.
                  </h2>
                  <hr class="border border-primary border-1 opacity-75" />
                  <h3>Select Your Database</h3>
                  <div className="text-center">
                    <Link
                      to="/MsSqlDropdown"
                      className="btn headerbutton btn-outline-primary"
                    >
                      Microsoft SQL
                    </Link>
                    <Link
                      to="/MySqlDropdown"
                      className="btn headerbutton btn-outline-success"
                    >
                      MYSQL
                    </Link>
                    <Link
                      to="/PostgreSqlDropdown"
                      className="btn headerbutton btn-outline-warning"
                    >
                      PostgreSQL
                    </Link>
                    <Link to="/" className="btn headerbutton btn-outline-info">
                      Oracle Databse
                    </Link>
                    <hr class="border border-primary border-1 opacity-75" />    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
